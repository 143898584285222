import DBWorker from "worker-loader!@/workers/worker";
import LogWorker from "worker-loader!@/workers/log.worker";
import { TaskEventType } from "./WorkerEventType";

class ProxyApi {
  constructor() {
    this.events = new Map();
    this.taskMap = {};
    this.taskID = 1;
    this.init();
    this.onLogout = undefined;
  }
  init() {
    this.worker = new DBWorker();
    this.worker.onmessage = this.onWorkerMessage.bind(this);
  }
  clear() {
    this.postMessage({
      task: "clear",
    }).then(() => {});
    this.worker.terminate();
    this.worker = undefined;
    this.taskID = 1;
    this.taskMap = {};
    this.events.clear();
    this.isInitDatabase = false;
    this.init();
  }

  on(key, func) {
    let p = this.events.get(key);
    if (!p) {
      this.events.set(key, [func]);
    } else {
      p.push(func);
    }
  }

  emit(key, arg) {
    let k = this.events.get(key);
    if (k) {
      k.forEach((func) => {
        func(arg);
      });
    }
  }

  off(key, func) {
    if (func) {
      let k = this.events.get(key);
      let idx = k.findIndex((f) => f === func);
      if (idx !== -1) {
        k.splice(idx, 1);
      }
    } else {
      this.offAll(key);
    }
  }

  offAll(key) {
    if (key) {
      this.events.delete(key);
    } else {
      this.events.clear();
    }
  }

  onWorkerMessage(e) {
    const { taskID, task, data, __progress } = e.data; //JSON.parse(e.data);

    if (task === "error-worker") {
      this.log({
        key: "error-worker",
        content: data.content,
        filename: data.filename,
      });
      return;
    }
    if (task === "logout") {
      //之后改成监听
      this.onLogout && this.onLogout();
      return;
    } else if (task === "data-logout") {
      //可以加个弹窗
      this.emit(task);
      return;
    } else if (task && task.startsWith && task.startsWith("notify_")) {
      this.emit(task, data);
    }
    if (__progress) {
      const promise = this.taskMap[taskID || task];
      if (promise.onProgress) {
        promise.onProgress(data);
      }
    } else {
      this.cbk(taskID || task, data);
    }
  }

  cbk(taskId, data) {
    // if (!data) return;
    if (taskId) {
      const promise = this.taskMap[taskId];
      if (data.__error) {
        promise && promise.reject(data);
      } else {
        promise && promise.resolve(data);
      }
      delete this.taskMap[taskId];
    }
  }

  async postMessage({ task, data, once, onProgress }) {
    if (!task) {
      this.logSubmit({ error: "task不存在" });
    }
    data = data || {};
    const taskId = once ? task : this.taskID++;
    let promise = new Promise((resolve, reject) => {
      this.taskMap[once ? task : taskId] = {
        resolve,
        reject,
        taskId,
        onProgress,
      };
      const requestBody = {
        task: task,
        taskID: once ? null : taskId,
        data,
      };
      this.worker.postMessage(requestBody);
    });
    promise.taskId = taskId;
    return promise;
  }

  async initProxy(info) {
    if (this.isInitDatabase) {
      return Promise.resolve(true);
    }
    if (this.initDatabasePromise) return this.initDatabasePromise;
    this.initDatabasePromise = this.postMessage({
      task: TaskEventType.READY,
      once: true,
      data: info,
    })
      .then((enabled) => {
        this.isInitDatabase = true;
        this.initDatabasePromise = undefined;
        return enabled;
      })
      .catch(() => {
        this.initDatabasePromise = undefined;
        this.isInitDatabase = false;
        this.log(info);
        return false;
      });
    return this.initDatabasePromise;
  }
  log(data) {
    this.initLogWork();
    return this.postLogMessage("log.append", data);
    // return this.postLogMessage('log.append', {
    //     key, content, filename
    // })
  }
  async postLogMessage(task, data) {
    data = data || {};
    const taskId = this.taskID++;
    let promise = new Promise((resolve, reject) => {
      this.taskMap[taskId] = { resolve, reject, taskId };
      const requestBody = {
        task: task,
        taskID: taskId,
        data,
      };
      this.logWorker.postMessage(requestBody);
    });
    promise.taskId = taskId;
    return promise;
  }
  initLogWork() {
    if (!this.logWorker) {
      this.logWorker = new LogWorker();
      this.logWorker.onmessage = (e) => {
        const { taskID, data } = e.data;
        this.cbk(taskID, data);
      };
    }
  }
  logSubmit(data) {
    this.initLogWork();
    return this.postLogMessage("log.submit", {
      userAgent: navigator.userAgent,
      u: localStorage.getItem("__user"),
      token: localStorage.getItem("mindmap_token"),
      ...data,
    });
  }
  moment(format, timestamp) {
    let a,
      jsdate = timestamp ? new Date(timestamp) : new Date();
    let pad = function (n, c) {
      if ((n = n + "").length < c) {
        return new Array(++c - n.length).join("0") + n;
      } else {
        return n;
      }
    };
    let txt_weekdays = [
      "星期日",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六",
    ];
    let txt_ordin = {
      1: "st",
      2: "nd",
      3: "rd",
      21: "st",
      22: "nd",
      23: "rd",
      31: "st",
    };
    let txt_months = [
      "",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let f = {
      // Day
      d: function () {
        return pad(f.j(), 2);
      },
      D: function () {
        return f.l().substr(0, 3);
      },
      j: function () {
        return jsdate.getDate();
      },
      l: function () {
        return txt_weekdays[f.w()];
      },
      N: function () {
        return f.w() + 1;
      },
      S: function () {
        return txt_ordin[f.j()] ? txt_ordin[f.j()] : "th";
      },
      w: function () {
        return jsdate.getDay();
      },
      z: function () {
        return (
          ((jsdate - new Date(jsdate.getFullYear() + "/1/1")) / 864e5) >> 0
        );
      },

      // Week
      W: function () {
        var a = f.z(),
          b = 364 + f.L() - a;
        var nd2,
          nd = (new Date(jsdate.getFullYear() + "/1/1").getDay() || 7) - 1;
        if (b <= 2 && (jsdate.getDay() || 7) - 1 <= 2 - b) {
          return 1;
        } else {
          if (a <= 2 && nd >= 4 && a >= 6 - nd) {
            nd2 = new Date(jsdate.getFullYear() - 1 + "/12/31");
            return date("W", Math.round(nd2.getTime() / 1000));
          } else {
            return (1 + (nd <= 3 ? (a + nd) / 7 : (a - (7 - nd)) / 7)) >> 0;
          }
        }
      },

      // Month
      F: function () {
        return txt_months[f.n()];
      },
      m: function () {
        return pad(f.n(), 2);
      },
      M: function () {
        return f.F().substr(0, 3);
      },
      n: function () {
        return jsdate.getMonth() + 1;
      },
      t: function () {
        var n;
        if ((n = jsdate.getMonth() + 1) == 2) {
          return 28 + f.L();
        } else {
          if ((n & 1 && n < 8) || (!(n & 1) && n > 7)) {
            return 31;
          } else {
            return 30;
          }
        }
      },

      // Year
      L: function () {
        var y = f.Y();
        return !(y & 3) && (y % 1e2 || !(y % 4e2)) ? 1 : 0;
      },
      //o not supported yet
      Y: function () {
        return jsdate.getFullYear();
      },
      y: function () {
        return (jsdate.getFullYear() + "").slice(2);
      },

      // Time
      a: function () {
        return jsdate.getHours() > 11 ? "pm" : "am";
      },
      A: function () {
        return f.a().toUpperCase();
      },
      B: function () {
        // peter paul koch:
        let off = (jsdate.getTimezoneOffset() + 60) * 60;
        let theSeconds =
          jsdate.getHours() * 3600 +
          jsdate.getMinutes() * 60 +
          jsdate.getSeconds() +
          off;
        let beat = Math.floor(theSeconds / 86.4);
        if (beat > 1000) beat -= 1000;
        if (beat < 0) beat += 1000;
        if (String(beat).length === 1) beat = "00" + beat;
        if (String(beat).length === 2) beat = "0" + beat;
        return beat;
      },
      g: function () {
        return jsdate.getHours() % 12 || 12;
      },
      G: function () {
        return jsdate.getHours();
      },
      h: function () {
        return pad(f.g(), 2);
      },
      H: function () {
        return pad(jsdate.getHours(), 2);
      },
      i: function () {
        return pad(jsdate.getMinutes(), 2);
      },
      s: function () {
        return pad(jsdate.getSeconds(), 2);
      },
      //u not supported yet

      // Timezone
      //e not supported yet
      //I not supported yet
      O: function () {
        var t = pad(Math.abs((jsdate.getTimezoneOffset() / 60) * 100), 4);
        if (jsdate.getTimezoneOffset() > 0) t = "-" + t;
        else t = "+" + t;
        return t;
      },
      P: function () {
        var O = f.O();
        return O.substr(0, 3) + ":" + O.substr(3, 2);
      },
      //T not supported yet
      //Z not supported yet

      // Full Date/Time
      c: function () {
        return (
          f.Y() +
          "-" +
          f.m() +
          "-" +
          f.d() +
          "T" +
          f.h() +
          ":" +
          f.i() +
          ":" +
          f.s() +
          f.P()
        );
      },
      //r not supported yet
      U: function () {
        return Math.round(jsdate.getTime() / 1000);
      },
    };

    return format.replace(/([a-zA-Z])/g, function (t, s) {
      let ret = "";
      if (t != s) {
        // escaped
        ret = s;
      } else if (f[s]) {
        // a date function exists
        ret = f[s]();
      } else {
        // nothing special
        ret = s;
      }
      return ret;
    });
  }
}

export const proxyApi = new ProxyApi();
