import axios from 'axios'
import CancelRequest from "./CancelRequest";
import {sendMessage} from "../utils/utils";

class Http {
    constructor() {
        this.instance = axios.create({timeout: 1000 * 60});
        // 实例化取消请求对象
        this.cancelRequest = new CancelRequest();

        const requestError = (error) => {
            if (!error.config) {
                return Promise.reject(error);
            }
            // 移除成功请求记录
            this.cancelRequest.removeRequestKey(error.config);

            if (error.response.data.code === 401) {
                return Promise.reject(error);
            }

            if (!error.response ||
                (error.response.status >= 400 && error.response.status !== 504)) {
                // console.log('--requestError-111-');
                if (error.config.repeatNumber > 0) {
                    error.config.repeatNumber--;
                    return new Promise((resolve, reject) => {
                        this.instance.request(error.config)
                            .then(res => {
                                resolve(res);
                            }).catch(err => {
                            reject(err);
                        })
                    });
                }
                return Promise.reject(error);
            }
            if (error.config.noReapeat) {
                return Promise.reject(error);
            }
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    this.instance.request(error.config)
                        .then(res => {
                            resolve(res);
                        }).catch(err => {
                        reject(err);
                    })
                }, 2000);
            });
        }
        const beforeRequest = (config) => {
            if (this.netInfo) {
                const token = this.netInfo.token;
                token && (config.headers.mindmap_token = token);
                config.headers.mindmap_device = this.netInfo.device;
            }
            // 增加时间戳
            // config.date = parseInt(+new Date() / 100);
            // 检查之前是否存在相同的请求，如果存在则取消。
            this.cancelRequest.cancelReq(config);
            // 记录当前请求
            this.cancelRequest.addCancelReqKey(config, axios.CancelToken);
            return config;
        }
        const beforeResponse = (res) => {
            // 移除成功请求记录
            this.cancelRequest.removeRequestKey(res.config);
            if (res.data && res.data.code === 401) {
                setTimeout(() => {
                    sendMessage({
                        task: 'logout',
                        data: ''
                    })
                }, 500)
            }
            return res.status === 200 ? Promise.resolve(res) : Promise.reject(res)
        }
        this.instance.interceptors.request.use(beforeRequest, requestError)
        this.instance.interceptors.response.use(beforeResponse, requestError)
    }

    setNetInfo(info) {
        this.netInfo = info;
    }

    post(url, data, repeat = false) {
        return this.instance.request({
            method: 'POST',
            url,
            data: data,
            repeatNumber: typeof repeat === "number" ? repeat : 0,
            noRepeat: typeof repeat === "boolean" ? !repeat : true
        })
    }

    get(url, data, repeat = false) {
        return this.instance.request({
            method: 'GET',
            url,
            params: data || undefined,
            noRepeat: !repeat
        })
    }
}

export const http = new Http();
