<template>
  <router-view />
  <ElectronUpdate />
  <set-config
    ref="setConfig"
    v-model:show="configVisible"
    :configActive="configActive"
    :key="configKey"
  />
  <loading-page
    :progress="loginProgress"
    v-if="loginProgress >= 0"
    :loginState="loginState"
    @reload="onReLogin"
  />
  <lock
    v-if="
      $store.state.isLogin &&
      config.lock &&
      config.lock.status === 'true' &&
      isLock == 'true'
    "
  />
  <confirm
    v-if="confirmDialog.visible"
    :title="confirmDialog.title"
    :subtitle="confirmDialog.subtitle"
    :confirm="confirmDialog.onConfirm"
    :color="confirmDialog.color"
    :only-confirm="confirmDialog.onlyConfirm"
    @close="closeConfirm"
  />
</template>

<script>
import ElectronUpdate from "./views/ElectronUpdate";
import { defaultTheme, LoginState, shortcutKeys } from "./utils/constaints";
import setConfig from "./views/pc/article/set-config";
import { vipMobiles } from "./utils/vipMobile";
import Confirm from "./views/pc/article/widget/Confirm";
import {
  getMindEdgeStyle,
  getMindNodeStyle,
  getMindEdgeLinkStyle,
} from "./utils/mindMapStyle";
import LoadingPage from "./views/LoadingPage";
import lockMixin from "./mixins/lock";
import { proxyApi } from "./workers/ProxyApi";

export default {
  name: "App",
  components: { LoadingPage, ElectronUpdate, setConfig, Confirm },
  mixins: [lockMixin],
  provide() {
    return {
      reload: this.reload,
      changeMindStyle: this.changeMindStyle,
    };
  },
  data() {
    return {
      mindMapCaches: {},
      confirmDialog: {
        onlyConfirm: false,
        visible: false,
        title: "",
        subtitle: "",
        color: "red",
        onConfirm: null,
        onClose: null,
      },
      LoginState,
      isRouterAlive: true,
      isGetInitData: false,
      config: {
        themeType: "dark",
        theme: defaultTheme,
        mindThemeColor: "blue",
        isWideScreen: "default",
        isTitleLine: "false",
        scrollPosition: "false",
        toolbarType: "default",
        segmentSpacing: 0.8,
        lineHeight: 1.5,
        pastePlain: "true",
        toolbarButtons: [],
        menuButtons: {},
        navbarButtons: {},
        mindCollapse: 3,
        textColor: "#de4e4e",
        textColorBg: null,
        textBgColor: "#3C373B",
        textBgBgColor: "#f4e6b9",
        editorMenuButtons: [],
        mindMenuButtons: [],
        chatgpt: "false",
        edgeEnabled: "false",
        mindHideEdgeLink: "true",
        mindEdgeShowLabel: "false",
        mindToolbarPosition: "left",
        alwaysMind: 3,
        alwaysEditor: "true",
        toolbarRobots: "true",
        addThinking: "false",
      },
      loginProgress: 0,
      fontList: [],
      relationList: [
        { id: "01", data: "因果", sort: 0 },
        { id: "02", data: "阻碍", sort: 1 },
        { id: "03", data: "方法", sort: 2 },
        { id: "04", data: "功能", sort: 3 },
        { id: "05", data: "特点", sort: 4 },
        { id: "06", data: "含义", sort: 5 },
      ],
      loginState: LoginState.noLogin,
      configVisible: false,
      configActive: "config",
      loadingTreeList: false,
      cloudTreeMap: {},
      localTreeMap: {},
      cloudTreeList: [],
      localTreeList: [],
      mindEdgeStyle: {},
      mindNodeStyle: {},
      mindEdgeLinkStyle: {},

      activeItem: undefined,
      groups: [],

      links: [],

      cursorElement: null,
      curGroup: null,
      groupList: [],
      mobile: "",
      isVip: false,
      configKey: 1,
      edges: [],
      tempMindDragNode: undefined,
    };
  },
  watch: {
    curGroup(val, old) {
      if (old && val && val.id === old.id && val.itemList === old.itemList) {
        return;
      }
      if (val) {
        localStorage.setItem("__curGroup", val.id);
        this.changeGroupList();
      } else {
        this.groupList = [];
        localStorage.removeItem("__curGroup");
      }
    },
    activeItem(val, old) {
      if (val !== old) {
        if (val) {
          if (
            !this.$route.path.startsWith("/testEditor") ||
            !this.$route.path.startsWith("/article")
          )
            return;
          if (this.$route.params.articleId !== val.id) {
            if (
              this.loginProgress === -1 &&
              !this.$route.path.startsWith("/share")
            ) {
              const path = this.$route.path.startsWith("/testEditor")
                ? "testEditor"
                : "article";
              this.$router.push({
                path: `/${path}/${val.id}`,
              });
            }
          }
        } else if (!this.$route.path.startsWith("/share")) {
          const path = this.$route.path.startsWith("/testEditor")
            ? "testEditor"
            : "article";
          this.$router.push({
            path: `/${path}`,
          });
        }
      }
    },
    "$store.state.isLogin": {
      async handler(val, old) {
        if (val !== old && val) {
          if (val) {
            await this.autoLogin();
          } else {
            this.loginProgress = -1;
          }
        }
      },
    },
    "config.theme": {
      handler(val, old) {
        this.changeMindStyle();
        if (this.config.theme.includes("dark7")) {
          document.body.classList.add("dark-7");
          document.body.classList.remove("light-7");
        } else {
          document.body.classList.remove("dark-7");
          document.body.classList.add("light-7");
        }
      },
    },
    "config.mindThemeColor": {
      handler(val, old) {
        if (val !== old) {
          this.changeMindStyle();
        }
      },
    },
    loginState(val, old) {
      if (
        val !== old &&
        val === LoginState.noLogin &&
        this.$route.path !== "/login"
      ) {
        // this.$store.commit("delToken");
        // this.$proxy.postMessage({
        //   task: 'clear'
        // })
        // this.$router.replace('/login');
      }
    },
    "$route.path": {
      handler() {
        if (
          this.$route.meta.requireAuth &&
          this.loginState === LoginState.noLogin &&
          this.$store.state.isLogin
        ) {
          this.autoLogin();
        } else {
          this.loginProgress = -1;
        }
      },
    },
  },
  created() {
    this.$proxy.onLogout = () => {
      this.clearList();
      this.logout();
    };
    this.changeMindStyle();
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      document.body.classList.add("mxs-mobile");
    }
    // this.$confirm(
    //   "亲爱的各位树友：<br> 为保证模型树网站的运行稳定和服务质量，本次模型树网站服务器将于北京时间2022年5月23日晚23点停机，进行相关功能升级。预计维护时间为晚上23点至次日凌晨1点，<br>请各位树友相互转告，并提前留意时间，23日23点之前保存文档。避免编辑的文档丢失等。<br>对于维护期间给您带来的不便，敬请谅解。<br><br><br>模型树官方 2022年5月22日",
    //   "2022年5月23日 网站维护升级公告",
    //   {
    //     dangerouslyUseHTMLString: true,
    //     confirmButtonText: "确定",
    //     showCancelButton: false,
    //   }
    // );

    window.addEventListener("popstate", this.preventSwipeNav);

    this.$proxy.on("data-logout", this.onReload);

    //快捷键
    document.addEventListener("keydown", (e) => {
      e = e || window.event;
      if (
        e.shiftKey &&
        (e.metaKey || e.ctrlKey) &&
        (e.key === "/" || e.key?.toLowerCase() === "o")
      ) {
        //测试新编辑器切换
        const p = localStorage.getItem("__test");
        localStorage.setItem("__test", p === "1" ? "0" : "1");
        window.location.reload();
        return;
      }
      const firstKey = e.ctrlKey
        ? "ctrl"
        : e.metaKey
        ? "meta"
        : e.altKey
        ? "alt"
        : e.shiftKey
        ? "shift"
        : "";
      if ((e.metaKey || e.ctrlKey) && !e.shiftKey) {
        if (
          firstKey &&
          e.key !== "Control" &&
          e.key !== "Meta" &&
          e.key !== "Alt" &&
          e.key !== "Shift"
        ) {
          const key = (firstKey + "+" + e.key).toLowerCase();
          const item = shortcutKeys.find((item) => item.shortcutKey === key);
          if (
            item &&
            item.enabled &&
            (!item.checkInput ||
              e.target.tagName === "BODY" ||
              e.target?.hasClass("mindmap-container"))
          ) {
            e.preventDefault();
            this.$proxy.emit(item.eventKey, item);
          }
        }
      }
    });
  },
  beforeUnmount() {
    this.$proxy.off("data-logout", this.onReload);
  },
  methods: {
    async onReLogin() {
      if (!this.reloadCount) {
        this.reloadCount = 1;
      } else {
        this.reloadCount++;
      }
      await proxyApi.logSubmit();
      this.autoLogin();
    },
    preventSwipeNav() {
      // 阻止默认的后退和前进行为
      window.history.pushState(null, null, window.location.href);
    },
    onReload(e) {
      this.showConfirm({
        onlyConfirm: true,
        title: "网络失败",
        subtitle: typeof e === "string" && e ? e : "刷新重试",
        visible: true,
        onConfirm: () => {
          window.location.reload();
        },
      });
    },
    updateConfig(config) {
      if (config.theme?.startsWith("dark")) {
        config.theme = defaultTheme;
        document.body.classList.remove("light-7");
        document.body.classList.add("dark-7");
      } else {
        document.body.classList.add("light-7");
        document.body.classList.remove("dark-7");
      }
      // this.config = Object.assign(this.config, config);
      this.config = { ...this.config, ...config };
    },
    async changeGroupList() {
      // if (!this.$store.state.isLogin ||  this.loginState !== LoginState.hasLogin) return;
      if (!this.curGroup) {
        this.groupList = [];
        return;
      }
      // 可分组获取
      const list = [];
      if (this.curGroup.itemList) {
        this.curGroup.itemList.forEach((item) => {
          const node = this.cloudTreeMap[item.articleId];
          if (node && !node.parent) {
            list.push(node);
          } else {
            this.deleteGroupItem(this.curGroup.id, item.articleId);
          }
        });
      } else {
        this.curGroup.itemList = [];
      }
      this.groupList = list;
    },
    async logout() {
      this.isMergeLocal = false;
      this.isMergeCloud = false;
      this.loginProgress = -1;
      clearInterval(this.progressTimer);
      await this.$api.config.clearConfig();
      this.$store.commit("delToken");
      this.$proxy.clear();
      window.location.href = "/login";
      // this.$router.replace("/login");
    },
    loginSuccess() {
      this.loginProgress = 98;
      this.$nextTick(() => {
        const href = localStorage.getItem("preRoute");
        const prePath = localStorage.getItem("prePath");
        if (prePath && prePath !== "/login") {
          localStorage.removeItem("prePath");
          this.$router.push(prePath);
        } else if (href && href !== "/login") {
          localStorage.removeItem("preRoute");
          this.$router.push(href);
        } else if (this.$route.path === "/login") {
          this.$router.push(
            "/article" + (this.activeItem ? "/" + this.activeItem.id : "")
          );
        } else {
          this.loginProgress = -1;
        }
      });
    },
    async autoLogin() {
      this.$proxy.log({
        key: "auto login start",
      });
      if (this.loginState === LoginState.loginIn) return;
      this.loginState = LoginState.loginIn;
      if (this.$store.state.isLogin) {
        this.loginState = LoginState.loginIn;
        try {
          let info = await this.$proxy.initProxy({
            token: this.$store.state.mindmap_token,
            device: this.$store.state.mindmap_device,
            user: this.$store.state.user
              ? {
                  uid: this.$store.state.user.accountId,
                }
              : undefined,
          });
          this.loginProgress = 10;
          if (info) {
            if (typeof info === "object" && info.accountId) {
              this.$store.commit("setUser", info);
            }
            this.isGetInitData = false;
            await this.initData();

            //登录成功
            this.loginState = LoginState.hasLogin;

            let user = JSON.parse(localStorage.getItem("__user"));
            this.mobile = user ? user.mobile : null;
            this.isVip = vipMobiles.includes(this.mobile);
          } else {
            this.$proxy.log({ key: "auto login error: no user" });
            if (this.reloadCount >= 2) {
              this.loginProgress = -1;
              window.location.href = "/login";
            } else {
              this.loginState = LoginState.errorLogin;
            }
          }
        } catch (e) {
          if (this.reloadCount >= 2) {
            this.loginProgress = -1;
            window.location.href = "/login";
          } else {
            this.loginState = LoginState.errorLogin;
          }
          this.$proxy.log({
            key: "auto login error",
            content: e && e.message,
          });
        }
      } else {
        this.loginProgress = -1;
        this.loginState = LoginState.noLogin;
      }
    },
    //刷新方法
    reload() {
      // 通过 this.isRouterAlive 控制 router-view 达到刷新效果
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    async initData() {
      //全部本地化
      let result = await this.$api.config.getConfig("1");
      if (result && result.config) {
        this.updateConfig(result.config);
        if (result.config.relationList) {
          this.relationList = result.config.relationList;
        }
      }
      if (!result.config.navbarButtons) {
        this.config.navbarButtons = {
          logo: true,
          save: true,
          share: true,
          mindmap: true,
          fullScreen: true,
          setting: true,
        };
      }
      if (
        !result.config.menuButtons ||
        JSON.stringify(result.config.menuButtons) == "{}"
      ) {
        this.config.menuButtons = {
          append: true,
          changeMindMap: true,
          rename: true,
          remove: true,
        };
      } else if (
        result.config.menuButtons &&
        result.config.menuButtons.group === undefined
      ) {
        // 设置默认值
        result.config.menuButtons.group = true;
      }
      if (
        !result.config.toolbarButtons ||
        JSON.stringify(result.config.toolbarButtons) == "[]"
      ) {
        this.config.toolbarButtons = [
          "bold",
          "btn2",
          "clearFormatting",
          "setting",
        ];
      }
      if (!result.config.shortcutMenu) {
        this.config.shortcutMenu = {
          location: "bottom",
          viewLocation: "right",
          flashThought: "false",
          chatgpt: "false",
          transfer: "true",
        };
      }
      if (!result.config.toolbarType) {
        this.isWideScreen = "default";
        this.isTitleLine = "false";
        this.toolbarType = "default";
        this.segmentSpacing = 0.8;
        this.lineHeight = 1.5;
        this.pastePlain = "true";
      }
      if (!result.config.mindQuadtreeType) {
        this.config.mindQuadtreeType = "ver";
      }
      if (!result.config.alwaysEditor) {
        this.config.alwaysEditor = "true";
      }
      this.loginProgress = 20;
      document.body.classList.add(
        "theme-" + (this.config.theme || defaultTheme)
      );
      this.fontList = result.fontList;
      this.isGetInitData = true;
      this.progressTimer = setInterval(() => {
        if (this.loginProgress >= 70) {
          clearInterval(this.progressTimer);
        } else {
          this.loginProgress += 0.2;
        }
      }, 50);
      if (!this.$route.path.startsWith("/share")) {
        const list = await this.$api.article.loadAll();
        if (this.loginProgress < 80) {
          this.loginProgress = 80;
        }
        if (list.length > 0) {
          let activeItem = this.formatStruct(list);
          if (activeItem) {
            this.activeItem = activeItem;
            activeItem.active = true;
            while (activeItem.parent) {
              activeItem.parent.expand = true;
              activeItem = activeItem.parent;
            }
          } else {
            activeItem = list[0];
            activeItem.active = true;
            this.activeItem = activeItem;
          }
        }
        this.cloudTreeList = list;
        const edges = await this.$api.edges.getAll();
        if (edges && edges.length > 0) {
          this.edges = edges;

          edges.forEach((edge) => {
            const source = this.findItemById(edge.source);
            const target = this.findItemById(edge.target);
            const tag = "link" + (edge.labelType === 2 ? 2 : 1);
            if (source) {
              source[tag] = true;
            }
            if (target) {
              target[tag] = true;
            }
          });
        }
        clearInterval(this.progressTimer);
      } else {
        this.loginProgress = 80;
      }
      await this.getAllGroup();
      this.loginProgress = 90;
      await this.getAllLinkConfig();
      this.loginProgress = 95;
      this.loginSuccess();
    },
    async clearList() {
      this.curGroup = null;
      this.groupList = [];
      this.loadingTreeList = false;
      this.cloudTreeList = [];
      this.activeItem = undefined;
      this.cloudTreeMap = {};
      await this.$api.article.clearList();
    },
    async loadTreeList() {
      if (this.loadingTreeList || this.cloudTreeList.length > 0) return;
      this.loadingTreeList = true;
      const list = await this.$api.article.loadAll();
      if (list.length > 0) {
        let activeItem = this.formatStruct(list);
        if (activeItem) {
          this.activeItem = activeItem;
          activeItem.active = true;
          while (activeItem.parent) {
            activeItem.parent.expand = true;
            activeItem = activeItem.parent;
          }
        } else {
          activeItem = list[0];
          activeItem.active = true;
          this.activeItem = activeItem;
        }
      }
      this.cloudTreeList = list;
      const edges = await this.$api.edges.getAll();
      if (edges && edges.length > 0) {
        this.edges = edges;
        edges.forEach((edge) => {
          const source = this.findItemById(edge.source);
          const target = this.findItemById(edge.target);
          if (!source) return;
          const tag = "link" + (source.labelType === 2 ? 2 : 1);
          if (source) {
            source[tag] = true;
          }
          if (target) {
            target[tag] = true;
          }
        });
      }
    },
    formatStruct(list) {
      let activeItem;
      list.forEach((row) => {
        this.addTreeMap(row);
        if (row.id === this.$route.params.articleId) {
          activeItem = row;
        }
        if (row.children) {
          row.hasChildren = row.children.length > 0;
          row.children.forEach((child) => {
            this.addTreeMap(child);
            child.parent = row;
          });
          let act = this.formatStruct(row.children);
          if (act) {
            activeItem = act;
          }
        }
      });
      return activeItem;
    },
    // 思维导图主题
    changeMindStyle(
      themeColor = "#6ba4fa",
      btnTextColor = "#ebebeb",
      firstBgColor = "#6ba4fa",
      secondBgColor = "#1A1A1A",
      thirdTextColor = "#ebebeb",
      lineThemeColor = "#A48AFF",
      type = "theme"
    ) {
      if (type == "theme") {
        switch (this.config.theme) {
          case "default":
            themeColor = "#0a0a0a";
            btnTextColor = "#FFFFFF";
            firstBgColor = "#0a0a0a";
            secondBgColor = "#F5F5F5";
            thirdTextColor = "#666666";
            lineThemeColor = "#A48AFF";
            break;
          case "dark":
            themeColor = "#6c9efa";
            firstBgColor = themeColor;
            break;
          case "dark2":
            themeColor = "#6065f0";
            firstBgColor = themeColor;
            break;
          case "dark3":
            themeColor = "#1380ff";
            firstBgColor = themeColor;
            break;
          case "dark4":
            themeColor = "#6ba4fa";
            firstBgColor = themeColor;
            break;
          case "light2":
            themeColor = "#0a0a0a";
            btnTextColor = "#FFFFFF";
            firstBgColor = "#0a0a0a";
            secondBgColor = "#F5F5F5";
            thirdTextColor = "#666666";
            lineThemeColor = "#A48AFF";
            break;
          case "light3":
            themeColor = "#0a0a0a";
            btnTextColor = "#FFFFFF";
            firstBgColor = "#0a0a0a";
            secondBgColor = "#F5F5F5";
            thirdTextColor = "#666666";
            lineThemeColor = "#A48AFF";
            break;
          default:
            break;
        }
      }
      if (this.config.mindThemeColor) {
        switch (this.config.mindThemeColor) {
          case "blue":
            themeColor = "#6ba4fa";
            firstBgColor = themeColor;
            break;
          case "purple":
            // themeColor = "#6065f0";
            themeColor = "#717cfc";
            firstBgColor = themeColor;
            break;
          case "grey":
            themeColor = "#424242";
            firstBgColor = "#202020";
            break;
          case "black":
            themeColor = "#404040";
            firstBgColor = themeColor;
            break;
          default:
            themeColor = "#6ba4fa";
            firstBgColor = themeColor;
            break;
        }
      }
      this.mindEdgeStyle = getMindEdgeStyle(themeColor);
      this.mindNodeStyle = getMindNodeStyle(
        themeColor,
        btnTextColor,
        firstBgColor,
        secondBgColor,
        thirdTextColor,
        lineThemeColor
      );
      this.mindEdgeLinkStyle = getMindEdgeLinkStyle(lineThemeColor);
    },
    // 提示信息
    tips(type, message) {
      if (this.timeOutTips) {
        window.clearTimeout(this.timeOutTips);
      }
      this.timeOutTips = window.setTimeout(() => {
        this.$message({
          message: message,
          type: type,
          grouping: true,
        });
      }, 500);
    },

    getNode(items, docId) {
      let idx = items.findIndex((r) => r.id === docId);
      if (idx !== -1) {
        return items[idx];
      }
      for (let i = 0; i < items.length; ++i) {
        if (items[i].children) {
          let p = this.getNode(items[i].children, docId);
          if (p) {
            return p;
          }
        }
      }
      return null;
    },
    recoverToParent(p, items) {
      const parent = !p || p.id === "root" ? undefined : p;
      if (parent && !parent.children) {
        parent.children = [];
        parent.hasChildren = true;
      }
      //需要区分本地还是网络
      const children = parent ? parent.children : this.cloudTreeList;
      items.forEach((item) => {
        item.parent = parent;
        this.formatItem(item);
      });
      children.push(...items);
    },
    appendTree(list, isLocal = false) {
      let roots = isLocal ? this.localTreeList : this.cloudTreeList;
      list.forEach((item) => {
        if (!item.parentId || item.parentId === "root") {
          roots.splice(item.sort, 0, item);
        } else {
          let p = this.getNode(roots, item.parentId);
          if (p) {
            p.hasChildren = true;
            item.parent = p;
            if (p.children) {
              p.children.splice(item.sort, 0, item);
            } else {
              p.children = [item];
            }
          }
        }
      });
    },
    getNodeById(docId) {
      if (!docId) return null;
      if (docId.startsWith("local-")) {
        return this.getNode(this.localTreeList, docId);
      } else {
        return this.getNode(this.cloudTreeList, docId);
      }
    },

    //同步思维导图
    mergeData(data, item, isLocal = false) {
      if (!data.children || data.children.length === 0) return;
      if (!item) {
        if ((this.isMergeLocal && isLocal) || (this.isMergeCloud && !isLocal))
          return;
        if (isLocal) {
          this.isMergeLocal = true;
        } else {
          this.isMergeCloud = true;
        }
      }

      let children;
      if (!item && (!data.id || data.id === "root")) {
        children = isLocal ? this.localTreeList : this.cloudTreeList;
      } else if (item) {
        item.hasChildren = true;
        children = item.children || [];
        item.children = children;
      }
      data.children.forEach((child, idx) => {
        if (children[idx]) {
          children[idx].parentId = (item && item.id) || undefined;
          if (child.id && child.id !== "root") {
            children[idx].title = child.title;
            children[idx].iconType = child.iconType;
            children[idx].id = child.id;
          }
        } else {
          children[idx] = {
            ...children[idx],
            title: child.title,
            id: child.id,
            iconType: child.iconType,
            parentId: (item && item.id) || undefined,
            parent: item || undefined,
          };
        }

        if (child.children && child.children.length > 0) {
          children[idx].hasChildren = true;
          this.mergeData(child, children[idx], isLocal);
        }
      });
    },
    async mindNewItem(nodeId, addSort, isLocal = false, title = "新建模型") {
      const pNode = this.findItemById(nodeId);
      let c = isLocal ? this.localTreeList : this.cloudTreeList;
      let sort =
        addSort >= 0
          ? addSort
          : pNode
          ? (pNode.children && pNode.children.length) || 0
          : c.length;
      let parentId = pNode ? pNode.id : undefined;
      let iconType = 0;
      let data = {
        title: title,
        sort,
        parentId,
        iconType,
      };
      if (addSort >= 0) {
        const updateSorts = [];
        for (let i = sort; i < pNode.children.length; ++i) {
          updateSorts.push({
            articleId: pNode.children[i].id,
            sort: i + 1,
          });
        }
        data.updateSorts = updateSorts;
      }
      let res = await this.$api.article.addOrUpdateArticleCatalog(
        data,
        isLocal
      );
      if (!res.isSuccess) {
        // this.$root.tips("error", res.message);
        this.$proxy.emit("data-logout", res.message || "");
        return;
      }
      const newItem = {
        id: res.data,
        ...data,
      };
      newItem.parent = pNode;
      if (pNode) {
        pNode.hasChildren = true;
        if (!pNode.children) {
          pNode.children = [];
        }
        pNode.children.splice(sort, 0, newItem);
      } else {
        c.push(newItem);
      }
      this.addTreeMap(newItem);
      return newItem;
    },
    addTreeMap(newItem) {
      this.cloudTreeMap[newItem.id] = newItem;
    },
    formatItem(item) {
      item.active = false;
      this.addTreeMap(item);
      if (item.children && item.children.length > 0) {
        item.hasChildren = true;
        item.children.forEach((child) => {
          child.parent = item;
          this.formatItem(child);
        });
      }
      return item;
    },
    async cloneArticle(item, parent, sort = -1) {
      if (!item) return;
      const newItem = await this.$api.article.cloneArticle(
        {
          articleId: item.id,
          targetArticleId: parent.id === "root" ? "" : parent.id,
          includeChildren: true,
        },
        item.id.startsWith("local-")
      );
      if (!newItem) return;
      this.formatItem(newItem);
      if (newItem) {
        newItem.parent = parent.id !== "root" ? parent : "";
      }
      const sorts = [];
      if (parent.id !== "root") {
        parent.hasChildren = true;
        if (!parent.children) {
          parent.children = [];
        }
        if (sort !== -1) {
          for (let i = sort; i < parent.children.length; ++i) {
            sorts.push({
              articleId: parent.children[i].id,
              sort: i + 1,
            });
          }
          parent.children.splice(sort, 0, newItem);
        } else {
          parent.children.push(newItem);
        }

        parent.expand = true;
        parent.active = false;
      } else {
        if (sort !== -1) {
          this.cloudTreeList.splice(sort, 0, newItem);
          for (let i = sort; i < this.cloudTreeList.length; ++i) {
            sorts.push({
              articleId: this.cloudTreeList[i].id,
              sort: i + 1,
            });
          }
        } else {
          this.cloudTreeList.push(newItem);
        }
      }
      this.setSelectItem(newItem);
      if (sort !== -1) {
        const updateParams = {
          articleId: newItem.id,
          parentId:
            newItem.parent && newItem.parent.id !== "root"
              ? newItem.parent.id
              : "",
          title: newItem.title,
          iconType: newItem.iconType,
          sort,
          updateSorts: sorts,
        };
        const res = await this.$api.article.addOrUpdateArticleCatalog(
          updateParams
        );
        if (!res.isSuccess) {
          this.tips("error", res.message);
        }
      }
      return newItem;
    },
    setSelectItem(item) {
      if (this.activeItem) {
        this.activeItem.active = false;
      }
      this.activeItem = item;
      this.activeItem.active = true;
    },
    removeFromParent(row) {
      if (row.parent && row.parent.children) {
        let children = row.parent.children;
        const idx = children.findIndex((r) => r.id === row.id);
        if (idx !== -1) {
          children.splice(idx, 1);
          if (children.length === 0) {
            row.parent.hasChildren = false;
          }
          return {
            parentId: row.parent && row.parent.id,
            sort: idx,
          };
        }
      } else if (!row.parent) {
        //从根目录删除
        let children = row.id.startsWith("local-")
          ? this.localTreeList
          : this.cloudTreeList;
        const idx = children.findIndex((r) => r.id === row.id);
        if (idx !== -1) {
          children.splice(idx, 1);
          return {
            sort: idx,
          };
        }
      }
      // 可以删除map中数据，也可以不删除，内存还在而已
    },
    checkChildrenHasActive(item) {
      if (item.active) {
        return true;
      }
      if (item.children) {
        for (let i = 0; i < item.children.length; ++i) {
          if (this.checkChildrenHasActive(item.children[i])) {
            return true;
          }
        }
      }
      return false;
    },
    getChildrenIds(id) {
      const item = this.findItemById(id);
      if (!item) return;
      const ids = [item.id];
      if (item.children) {
        item.children.forEach((child) => {
          ids.push(...this.getIds(child));
        });
      }
      return ids;
    },
    getIds(item) {
      const ids = [item.id];
      if (item.children) {
        item.children.forEach((child) => {
          ids.push(...this.getIds(child));
        });
      }
      return ids;
    },
    async mindDeleteItem(nodeId) {
      const item = this.cloudTreeMap[nodeId];
      if (item) {
        // 删除群列表中的item
        if (this.curGroup && !item.parent) {
          await this.deleteGroupItem(this.curGroup.id, nodeId, true);
        }
        const children = item.parent
          ? item.parent.children
          : nodeId.startsWith("local-")
          ? this.localTreeList
          : this.cloudTreeList;
        const idx = children.findIndex((r) => r.id === nodeId);
        if (idx !== -1) {
          if (this.checkChildrenHasActive(item)) {
            this.activeItem = undefined;
          }
          children.splice(idx, 1);
        }
        if (
          item.parent &&
          item.parent.children &&
          item.parent.children.length === 0
        ) {
          item.parent.hasChildren = false;
        }
        const ids = this.getIds(item);
        ids.forEach((id) => {
          delete this.cloudTreeMap[id];
        });
      }
    },
    search(keyword, last) {
      return new Promise((resolve) => {
        if (!keyword) return { data: [], last: 1000000 };
        const ids = Object.keys(this.cloudTreeMap);
        const p = [];
        let lastIdx = last;
        for (let i = last || 0; i < ids.length; ++i) {
          lastIdx = i + 1;
          const item = this.cloudTreeMap[ids[i]];
          if (item.title.includes(keyword)) {
            p.push({
              id: ids[i],
              title: item.title,
            });
          }
          if (p.length > 20) {
            break;
          }
        }
        resolve({ data: p, last: lastIdx });
      });
    },
    findItemById(id) {
      return this.cloudTreeMap[id];
    },
    async updateIconType(id, type) {
      const node = this.findItemById(id);
      if (!node) return;
      const res = await this.$api.article.updateIconType({
        articleId: id,
        iconType: type,
      });
      if (!res.isSuccess) {
        this.tips("error", res.message);
        return;
      }
      node.iconType = type;
    },
    async updateMarkIcon(id) {
      const node = this.findItemById(id);
      if (!node) return;
      if (this.$isMarkEmphasis(node)) {
        const res = await this.$api.article.updateMarkIcon({
          articleId: node.id,
          marked: false,
        });
        if (!res.isSuccess) {
          this.tips("error", res.message);
          return;
        }
        node.iconDetail = {
          marked: false,
        };
      } else {
        const res = await this.$api.article.updateMarkIcon({
          articleId: node.id,
          marked: true,
        });
        if (!res.isSuccess) {
          this.tips("error", res.message);
          return;
        }
        node.iconDetail = {
          marked: true,
        };
      }
    },
    cloneItemAndIds(item) {
      const ids = [];
      const result = {
        ...item,
        parent: undefined,
        iconDetail: item.iconDetail ? { ...item.iconDetail } : undefined,
        parentId: item.parent ? item.parent.id : undefined,
      };
      ids.push(item.id);
      if (item.children) {
        result.children = [];
        item.children.forEach((child) => {
          const s = this.cloneItemAndIds(child);
          ids.push(...s.ids);
          result.children.push(s.item);
        });
      }
      return { ids, item: result };
    },
    async removeArticle(id) {
      const item = this.cloudTreeMap[id];
      if (!item) {
        this.$proxy.log({ key: id, content: "cloudTreeMap no data" });
        return;
      }
      const s = this.cloneItemAndIds(item);
      await this.$api.article.removeArticle(id, s);
      if (!item.parentId || item.parentId === "root") {
        //删除组中成员
        const items = this.groups.filter(
          (group) =>
            group.itemList.findIndex((item) => item.articleId === id) !== -1
        );
        items.forEach((item) => {
          this.deleteGroupItem(item.id, id);
        });
      }
      return [{ ...item, parent: undefined }];
    },
    async mindMove(data, parent, roots, updateParams, isLocal = false) {
      const oldItem = this.findItemById(data.oldId);
      const newParent = this.findItemById(data.newId);
      const oldChildren =
        oldItem.parent?.children ||
        (isLocal ? this.localTreeList : this.cloudTreeList);
      const idx = oldChildren.findIndex((r) => r.id === oldItem.id);
      if (idx !== -1) {
        oldChildren.splice(idx, 1);
        if (oldChildren.length === 0 && oldItem && oldItem.parent) {
          oldItem.parent.hasChildren = false;
        }
      }
      if (newParent && !newParent.children) {
        newParent.children = [];
      }
      const newIconType = this.$getNewIconType(oldItem, newParent);
      const newChildren = newParent
        ? newParent.children
        : isLocal
        ? this.localTreeList
        : this.cloudTreeList;
      oldItem.parent = newParent ? newParent : undefined;
      oldItem.parentId = newParent ? newParent.id : undefined;
      if (newParent) {
        newParent.hasChildren = true;
      }
      updateParams.iconType = newIconType;
      oldItem.iconType = newIconType;
      newChildren.splice(data.sort, 0, oldItem);

      const res = await this.$api.article.addOrUpdateArticleCatalog(
        updateParams,
        isLocal
      );
      if (!res.isSuccess) {
        this.$proxy.emit("data-logout", res.message || "");
      }
    },

    async getAllGroup() {
      const res = await this.$api.groups.getAll(false);
      if (res.isSuccess && res.data.length > 0) {
        res.data.sort((a, b) => a.sort - b.sort);
        this.groups = res.data;
      } else {
        this.groups = [];
      }
      if (this.$route.path.startsWith("/article")) {
        const curGroup = localStorage.getItem("__curGroup");
        if (curGroup) {
          const item = this.groups.find((r) => r.id === curGroup);
          if (item) {
            this.curGroup = item;
          }
        }
      }
    },
    async addGroup(data) {
      const res = await this.$api.groups.saveGroup(data, "new", false);
      if (res.isSuccess) {
        this.groups.unshift(res.data);
      }
    },
    async updateGroup(data) {
      // await this.$api.groups.deleteGroup(data.id, false);
      await this.$api.groups.saveGroup({
        id: data.id,
        groupName: data.groupName,
        sort: data.sort,
      });
    },
    async deleteGroup(item) {
      const res = await this.$api.groups.deleteGroup(item.id, false);
      if (res.isSuccess) {
        const idx = this.groups.findIndex((r) => r.id === item.id);
        if (idx !== -1) {
          this.groups.splice(idx, 1);
        }
      }
    },
    async addGroupItem(groupId, articleId, sort = 0) {
      const group = this.groups.find((r) => r.id === groupId);
      if (!group.itemList) {
        group.itemList = [];
      }
      const len = group.itemList?.length || 0;
      const itemSort =
        group.itemList.length > 0
          ? sort < 0
            ? sort
            : sort < len && sort >= 0
            ? group.itemList[sort].sort
            : group.itemList[len - 1].sort + 1
          : 0;
      const res = await this.$api.groups.saveGroupItem(
        { groupId, articleId, sort: itemSort },
        false
      );
      if (res.isSuccess) {
        if (group) {
          if (!group.itemList) group.itemList = [];
          const idx = group.itemList.findIndex(
            (r) => r.articleId === articleId
          );
          if (idx !== -1) {
            group.itemList.splice(idx, 1);
          }
          group.itemList.splice(sort, 0, { articleId, sort: itemSort });
          if (this.curGroup) {
            const idx = this.groupList.findIndex((r) => r.id === articleId);
            idx !== -1 && this.groupList.splice(idx, 1);
            const node = this.cloudTreeMap[articleId];
            if (node && !node.parent) {
              this.groupList.splice(sort, 0, node);
            } else {
              await this.deleteGroupItem(this.curGroup.id, articleId);
            }
          }
          for (let i = sort + 1; i < group.itemList.length; ++i) {
            group.itemList[i].sort += 1;
          }
          // group.itemList.unshift({
          //   articleId,
          // });
        }
      }
      return res;
    },
    async deleteGroupItem(groupId, articleId, isNet = true) {
      const idx = this.groupList.findIndex((r) => r.id === articleId);
      if (idx !== -1) {
        this.groupList.splice(idx, 1);
      }
      if (!isNet) {
        const group = this.groups.find((r) => r.id === groupId);
        if (group) {
          if (!group.itemList) group.itemList = [];
          const idx = group.itemList.findIndex(
            (r) => r.articleId === articleId
          );
          if (idx !== -1) {
            group.itemList.splice(idx, 1);
          }
        }
        return;
      }
      const res = await this.$api.groups.deleteGroupItem(
        { groupId, articleId },
        false
      );
      if (res.isSuccess) {
        const group = this.groups.find((r) => r.id === groupId);
        if (group) {
          if (!group.itemList) group.itemList = [];
          const idx = group.itemList.findIndex(
            (r) => r.articleId === articleId
          );
          if (idx !== -1) {
            group.itemList.splice(idx, 1);
          }
        }
      }
    },
    async updateGroupItem(data) {
      // await this.deleteGroupItem(data.groupId, data.articleId);
      return await this.addGroupItem(data.groupId, data.articleId, data.sort);
    },
    async updateGroupChecked(articleId) {
      if (!articleId) return;
      this.groups.forEach((group) => {
        group.checked =
          group.itemList &&
          group.itemList.findIndex((r) => r.articleId === articleId) !== -1;
      });
    },
    async getAllLinkConfig() {
      const res = await this.$api.article.getAllLink();
      const links = [];
      res.data.forEach((item) => {
        if (item.parentId) {
          if (!links[item.parentId]) {
            links[item.parentId] = {};
          }
          links[item.parentId][item.childId] = { arrowType: 1, ...item };
        }
      });
      this.links = links;
    },
    async changeEdgeConfig(options) {
      const res = await this.$api.article.addLink({
        parentId: options.sourceId,
        childId: options.targetId,
        orientation: options.orientation,
      });
      if (res.isSuccess) {
        //
      } else {
        throw new Error("net error");
      }
    },
    async deleteArrow(options) {
      const res = await this.$api.article.removeLink({
        parentId: options.sourceId,
        childId: options.targetId,
      });
      if (res.isSuccess) {
        //
      } else {
        throw new Error("net error");
      }
    },
    showConfirm(options) {
      this.confirmDialog = {
        ...this.confirmDialog,
        title: "",
        subtitle: "",
        visible: true,
        ...options,
      };
    },
    closeConfirm() {
      this.confirmDialog.onClose && this.confirmDialog.onClose();
      this.confirmDialog.visible = false;
      this.confirmDialog.onConfirm = null;
      this.confirmDialog.onClose = null;
    },
    async updateTreeItem(data) {
      await this.$api.article.addOrUpdateArticleCatalog(data);
    },
    addEdge(data, isLocal) {
      const source = this.findItemById(data.source);
      const target = this.findItemById(data.target);
      const tag = "link" + (data.labelType === 2 ? 2 : 1);
      if (source) {
        source[tag] = true;
      }
      if (target) {
        target[tag] = true;
      }
      this.edges.push(data);
      return this.$api.edges.addEdge(data, isLocal);
    },
    deleteEdge(items) {
      requestAnimationFrame(() => {
        items.forEach((data) => {
          const idx = this.edges.findIndex(
            (edge) => edge.source === data.source && edge.target === data.target
          );
          if (idx !== -1) {
            const [old] = this.edges.splice(idx, 1);
            const tag = "link" + (old.labelType === 2 ? 2 : 1);
            const tdx = this.edges.findIndex(
              (edge) =>
                (edge.labelType || 1) === (old.labelType || 1) &&
                (edge.source === old.source || edge.target === old.source)
            );
            const source = this.findItemById(old.source);
            if (tdx === -1) {
              source[tag] = false;
            }

            const cdx = this.edges.findIndex(
              (edge) =>
                (edge.labelType || 1) === (old.labelType || 1) &&
                (edge.source === old.target || edge.target === old.target)
            );
            const target = this.findItemById(old.target);
            if (cdx === -1) {
              target[tag] = false;
            }
          }
        });
      });
      return this.$api.edges.deleteEdge({ items });
    },
    updateEdge(options) {
      requestAnimationFrame(() => {
        const idx = this.edges.findIndex(
          (edge) =>
            edge.source === options.oldItem.source &&
            edge.target === options.oldItem.target
        );
        if (idx !== -1) {
          this.edges[idx] = {
            ...this.edges[idx],
            ...options.newItem,
          };
        }
      });
      return this.$api.edges.updateEdge(options);
    },
    getChildrenByFilter(map, children) {
      let s = [];
      if (children) {
        children.forEach((child) => {
          if (!map.has(child.id)) {
            map.add(child.id);
            s.push({
              ...child,
              children: this.getChildrenByFilter(map, child.children),
              link2: false,
            });
          }
        });
      }
      return s;
    },
    getShowEdgesAndChildrens(node) {
      const newChildren = [];
      let map = new Set();
      map.add(node.id);
      const items = [];
      this.edges.forEach((edge) => {
        let item, label;
        if (
          edge.labelType === 2 &&
          (edge.target === node.id || edge.source === node.id)
        ) {
          item = this.findItemById(
            edge.target === node.id ? edge.source : edge.target
          );
          label = edge.target === node.id ? edge.label2 : edge.label1;
        } else if (edge.source === node.id) {
          item = this.findItemById(edge.target);
          label = edge.title;
        }
        if (item && !map.has(item.id)) {
          map.add(item.id);
          items.push({ item, label });
        }
      });
      items.forEach(({ item, label }) => {
        newChildren.push({
          ...item,
          children: this.getChildrenByFilter(map, item.children),
          style: {
            ...item.style,
            beforeWidth: undefined,
            afterWidth: undefined,
          },
          label,
          link2: false,
        });
      });
      return {
        ...node,
        link2: false,
        children: newChildren,
      };
    },
  },
};
</script>

<style>
#app {
  font-family: PingFang SC;
  /* height: 100vh; */
}
</style>
